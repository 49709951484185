export const initialState: any = { state: {} };

const mainReducer = (state: any, action: any) => {
  switch (action.type) {
    case "setAdmin": {
      return { state: { ...state.admin, ...action.value } };
    }
    case "clearAdmin": {
      return { state: initialState };
    }
    default: {
      throw new Error();
    }
  }
};
export default mainReducer;
