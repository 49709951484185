import { getLanguage } from "../translations/helper";

type paramsType = {
  [key: string]: string;
};

export const path = (linkTo: string, params: paramsType = {}) => {
  params["lang"] = getLanguage();

  if (!linkTo?.includes(":lang")) {
    linkTo = "/:lang" + linkTo;
  }

  for (let key in params) {
    linkTo = linkTo.replace(":" + key, params[key]);
  }

  return linkTo;
};
