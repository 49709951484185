import React from "react";
import Description from "components/atoms/Description";
import { getNestedValue } from "../../../system/helpers/helperFunctions";

const ExpandedRow = ({ items, rowData }: any) => {
  return (
    <div className={"expanded-row"}>
      {items?.map((item: any) => {
        return (
          <Description
            title={item.title}
            value={getNestedValue(rowData, item.accessor)}
          />
        );
      })}
    </div>
  );
};

export default ExpandedRow;
