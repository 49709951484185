import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_ROLES,
  API_GET_ROLE,
  API_CREATE_ROLE,
  API_UPDATE_ROLE,
  API_DELETE_ROLE,
  API_GET_AUTHORITIES,
} from "system/api/apiUrls";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  AuthoritiesDataType,
  RolesDataType,
  RolesItemType,
} from "modules/roles/types";

const getRolesKey = "GetRolesSecurityGroupsKey";
const getRoleKey = "GetRoleSecurityGroupsKey";
const getRoleAuthoritiesKey = "GetRoleAuthoritiesKey";
export const useGetRoles = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRolesKey],
    (): Promise<RolesDataType> =>
      apiRequest({
        url: API_GET_ROLES,
        method: "get",
      })
  );
};
export const useGetRolesAuthorities = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRoleAuthoritiesKey],
    (): Promise<AuthoritiesDataType> =>
      apiRequest({
        url: API_GET_AUTHORITIES,
        method: "get",
      })
  );
};

export const useGetRole = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRoleKey, id],
    (): Promise<RolesItemType> =>
      apiRequest({
        url: generatePath(API_GET_ROLE, {
          id,
        }),
        method: "get",
      })
  );
};

export const useCreateRole = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_ROLE,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRolesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateRole = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_ROLE, {
          id: data.id,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRolesKey);
        queryClient.invalidateQueries(getRoleKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteRole = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_ROLE, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRolesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
