import React from "react";

const PageNotFound = () => (
  <div className={"page-not-found"}>
    <span className={"page-not-found__top"}>404</span>
    <span className={"page-not-found__bottom"}>
      Sorry, we couldn't find that page...
    </span>
  </div>
);

export default PageNotFound;
