import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_GET_ACTION_LOG } from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { ActionLogDataType } from "modules/actionLog/types";

const getActionLogKey = "getActionLogKey";

export const useGetActionLog = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getActionLogKey, filter],
    (): Promise<ActionLogDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_ACTION_LOG, filter),
        method: "get",
      })
  );
};
