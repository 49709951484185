export const modifyCreateUpdateReqData = (data: any) => {
  const newData: any = {
    name: data.name,
    description: data.description,
    authorities: [],
  };
  for (let key in data.authorities) {
    if (!!data.authorities[key]) {
      newData.authorities.push(key);
    }
  }
  return newData;
};
