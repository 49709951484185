import React from "react";
import { Redirect } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { AccessToType } from "system/helpers/types";
import { usePermissionAccess } from "modules/admin/helperHooks";

interface RoleWrapperProps {
  children: any;
  accessTo: AccessToType;
  redirect?: boolean;
  defaultComponent?: any;
}

export const RoleWrapper: React.FC<RoleWrapperProps> = ({
  accessTo,
  redirect = false,
  defaultComponent = null,
  children,
  ...props
}) => {
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const accessControl = checkPermission(accessTo);
  if (!accessControl && !isSuperAdmin && redirect) {
    return <Redirect to={APP_ROUTES.users.index} />;
  } else if (!accessControl && !isSuperAdmin && !redirect) {
    return defaultComponent;
  }

  return children;
};
