import * as Yup from "yup";
import { TransType } from "../translations/types";

export const isValidEmail = (_t: TransType) =>
  Yup.string()
    .email(_t("invalid_email"))
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      _t("invalid_email")
    )
    .required(_t("required_email"));

export const isValidPassword = (_t: TransType) =>
  Yup.string()
    .min(8, _t("password_must_be_longer"))
    .max(30, _t("password_must_be_shorter"))
    .matches(
      /^(?=.*?[0-9]).{8,}$/,
      _t("password_must_contain_at_least_one_number")
    )
    .matches(/(?=.*?[a-z])/, _t("password_must_contain_at_least_one_lowercase"))
    .matches(/(?=.*?[A-Z])/, _t("password_must_contain_at_least_one_uppercase"))
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      _t("password_must_contain_at_least_one_special_character")
    );
