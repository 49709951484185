const projectEnv: string = !!process.env ? process.env.NODE_ENV : "";

const developmentUrl = "http://localhost:3000/";

let backendUrl: string = "";
const host = window.location.host;
const protocol = window.location.protocol;
switch (projectEnv) {
  case "development":
    backendUrl = developmentUrl;
    break;
  case "staging":
    backendUrl = `${protocol}//${host}`;
    break;
  default:
    backendUrl = `${protocol}//${host}`;
    break;
}
export default backendUrl;