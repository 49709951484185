import { useMutation } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_CHANGE_PASSWORD, API_DELETE_USER } from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { generatePath } from "react-router-dom";

export const useChangePassword = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CHANGE_PASSWORD, { id: data.id }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
