import { ParamsKey } from "./types";
import { renderToString } from "react-dom/server";

export const getLanguage = () => localStorage.getItem("lang") || "en";

export const replaceParamInTrans = (
  trans: string,
  paramKey: string,
  value: string | number
): string => {
  let result: string = trans;
  const searchKey = `{${paramKey}}`;
  let replaceValue = typeof value !== "object" ? value : renderToString(value);
  result = result.replace(searchKey, `${replaceValue}`);
  if (result.includes(searchKey)) {
    result = replaceParamInTrans(result, paramKey, value);
  }

  return result;
};

export const modifyTrans = (value: string, params: ParamsKey): string => {
  let result: string = value;
  const paramsKeys: string[] = Object.keys(params);
  const paramsValues: any = Object.values(params);
  paramsKeys.forEach((paramKey, idx) => {
    result = replaceParamInTrans(result, paramKey, paramsValues[idx]);
  });

  return result;
};
