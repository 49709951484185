import React from "react";
import Update from "modules/users/components/Update";

const UsersUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default UsersUpdatePage;
