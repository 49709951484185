import React from "react";
import Update from "modules/merchants/components/Update";

const MerchantUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default MerchantUpdatePage;
